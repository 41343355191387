import { features, featureKeys } from '../constants/features';

function findFeatureConfig(featureKey) {
  return features.find((f) => f.key === featureKey);
}

export function isFeatureEnabledForUser(featureKey, userId = '000000') {
  const usersList = process.env[`REACT_APP_${featureKey}_FOR_USERS`] || '';

  return String(usersList).split(',').includes(`${userId}`);
}

export function rewardsv2Enabled(userId) {
  return !!findFeatureConfig(featureKeys.REWARDS_V2)?.active ||
    isFeatureEnabledForUser(featureKeys.REWARDS_V2, userId);
}

export function neighborNurtureEnabled(userId) {
  return !!findFeatureConfig(featureKeys.NEIGHBOR_NURTURE_ENABLED)?.active ||
    isFeatureEnabledForUser(featureKeys.NEIGHBOR_NURTURE_ENABLED, userId);
}

export function rgPayEnabled(userId) {
  return !!findFeatureConfig(featureKeys.RGPAY_ENABLED)?.active ||
  isFeatureEnabledForUser(featureKeys.RGPAY_ENABLED, userId);
}
