/* eslint-disable import/no-cycle */
import { ROUTES } from '../constants/misc';
import client, { xhr } from '../helpers/httpClient';

export default {
  fetchPropertyManager() {
    return client.get(ROUTES.PROPERTY_MANAGER);
  },

  fetchPropertyManagerUsers(filters) {
    return client.get(ROUTES.PROPERTY_MANAGER_USERS(filters));
  },

  fetchPropertyManagerUser(user_id) {
    return client.get(ROUTES.PROPERTY_MANAGER_USER(user_id));
  },

  updatePropertyManagerUser(user_id, params) {
    return client.put(ROUTES.PROPERTY_MANAGER_USER(user_id), params);
  },

  fetchProperties(filters) {
    return xhr.get(ROUTES.PROPERTIES(filters));
  },

  fetchNeighborNurtureProperties(filters) {
    return xhr.get(ROUTES.NEIGHBOR_NURTURE_PROPERTIES(filters));
  },

  updateNeighborNurtureProperty(listingId, params) {
    return client.put(ROUTES.NEIGHBOR_NURTURE_PROPERTY(listingId), params);
  },

  updateGlobalOverrideNeighborNurture(params) {
    return client.put(ROUTES.NEIGHBOR_NURTURE_GLOBAL_OVERRIDE, params);
  },

  fetchPropertyManagers(filters) {
    return xhr.get(ROUTES.PROPERTY_MANAGERS(filters));
  },

  fetchProperty(listing_id) {
    return client.get(ROUTES.PROPERTY(listing_id));
  },

  fetchPropertyUsers(listing_id, filters) {
    return client.get(ROUTES.PROPERTY_USERS(listing_id, filters));
  },

  updateProperty(listing_id, params) {
    return client.put(ROUTES.PROPERTY(listing_id), params);
  },

  updateKnock(listing_id, params) {
    return client.put(ROUTES.PROPERTY_KNOCK_UPDATES(listing_id), params);
  },

  initiateAutoInviter(listing_id) {
    return client.put(ROUTES.PROPERTY_AUTO_INVITER(listing_id));
  },

  validateListingThirdPartyIntegration(listing_id, payload) {
    return client.post(ROUTES.PROPERTY_VALIDATE_INTEGRATION(listing_id), payload);
  },

  deleteListingThirdPartyIntegration(listing_id, integrationType) {
    return client.delete(ROUTES.PROPERTY_DELETE_INTEGRATION(listing_id, integrationType));
  },

  validateManagerThirdPartyIntegration(propertyManagerID, payload) {
    return client.post(ROUTES.PROPERTY_MANAGER_VALIDATE_INTEGRATION(propertyManagerID), payload);
  },

  deleteManagerThirdPartyIntegration(propertyManagerID, integrationType) {
    return client.delete(
      ROUTES.PROPERTY_MANAGER_DELETE_INTEGRATION(propertyManagerID, integrationType),
    );
  },

  fetchPropertyFloorplans(listing_id, filters) {
    return client.get(ROUTES.PROPERTY_FLOORPLANS(listing_id, filters));
  },

  fetchPropertyNotifications(listing_id) {
    return client.get(ROUTES.PROPERTY_NOTIFICATIONS(listing_id));
  },

  fetchEntitySentiment(listing_id, is_prospect) {
    return client.get(ROUTES.ENTITY_SENTIMENT(listing_id, is_prospect));
  },

  fetchProximityWords(listing_id, filters) {
    return client.get(ROUTES.PROXIMITY_WORDS(listing_id, filters));
  },

  fetchResidentResponses(listing_id, filters) {
    return client.get(ROUTES.RESIDENT_RESPONSES(listing_id, filters));
  },

  fetchTopResidents(listing_id) {
    return client.get(ROUTES.TOP_RESIDENTS(listing_id));
  },

  fetchMovedInInterests(listing_id) {
    return client.get(ROUTES.MOVED_IN_INTERESTS(listing_id));
  },

  fetchLostConcerns(listing_id) {
    return client.get(ROUTES.LOST_CONCERNS(listing_id));
  },

  fetchRewardSnapshot(listing_id) {
    return client.get(ROUTES.REWARD_SNAPSHOT(listing_id));
  },

  inviteResidents(listing_id, residents) {
    return client.post(ROUTES.INVITE_RESIDENTS(listing_id), residents);
  },

  updatePropertyBillingContact(listingId, params) {
    return client.put(ROUTES.UPDATE_PROPERTY_BILLING_CONTACT(listingId), params);
  },

  sendWidgetInstallationInstructions(listingId, params) {
    return client.put(ROUTES.SEND_WIDGET_INSTALLATION_INSTRUCTION(listingId), params);
  },

  updateAnnounceWidget(listingId, params) {
    return client.put(ROUTES.UPDATE_ANNOUNCE_WIDGET(listingId), params);
  },

  updatePrintAndMakeCopies(listingId, params) {
    return client.put(ROUTES.UPDATE_PRINT_AND_MAKE_COPIES(listingId), params);
  },

  activateSubscription(listingId) {
    return client.put(ROUTES.ACTIVATE_SUBSCRIPTION(listingId));
  },

  fetchPaymentHistory(listingId, year) {
    return client.get(ROUTES.FETCH_PAYMENT_HISTORY(listingId, year));
  },

  exportPaymentInvoice(params) {
    return client.post(ROUTES.EXPORT_PAYMENT_INVOICE, params);
  },

  searchManagerUsers(params) {
    return xhr.get(ROUTES.PROPERTY_MANAGER_USERS(params));
  },

  resumeService(listingId) {
    return client.put(ROUTES.RESUME_SERVICE(listingId));
  },

  retryPayment(listingId) {
    return client.put(ROUTES.RETRY_PAYMENT(listingId));
  },

  postMessengerConfig(listingId, formData) {
    return client.put(ROUTES.POST_MESSENGER_CONFIG(listingId), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addStripeCardAccount(params) {
    return client.post(ROUTES.ADD_STRIPE_CARD_ACCOUNT, params);
  },

  addStripeBankAccount(params) {
    return client.post(ROUTES.ADD_STRIPE_BANK_ACCOUNT, params);
  },

  addBillingAddress(params) {
    return client.post(ROUTES.ADD_BILLING_ADDRESS, params);
  },

  removeStripeCardAccount(params) {
    return client.put(ROUTES.REMOVE_STRIPE_ACCOUNT, params);
  },

  fetchPropertyBillingAccounts(params) {
    return client.get(ROUTES.FETCH_USER_BILLING_ACCOUNTS, params);
  },

  verifyBankAccount(params) {
    return client.put(ROUTES.VERIFY_BANK_ACCOUNT, params);
  },
};
