import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY);
const bugsnagReactClient = bugsnagClient.use(bugsnagReact, React);

export {
  bugsnagReactClient
};

export default bugsnagClient;
