import React, { Component } from 'react';
import { Row } from 'antd';
import { bugsnagReactClient } from '../../helpers/bugsnag';

const ErrorBugsnagBoundary = bugsnagReactClient.getPlugin('react');

const FallbackUI = () => {
  return (
    <Row className="errorboundary__wrapper" type="flex" justify="center" align="middle">
      <h1>An unexpected error occured</h1>
      <p>Kindly refresh your browser. If error persists please send us a message at support@rentgrata.com.</p>
    </Row>
  )
}

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    bugsnagReactClient.notify({error, info: errorInfo});
  }

  render() {
    return (
      <ErrorBugsnagBoundary notifyReleaseStages={['production', 'staging']} FallbackComponent={FallbackUI}>
        {this.props.children}
      </ErrorBugsnagBoundary>
    )
  }
}
