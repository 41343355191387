export const featureKeys = {
  REWARDS_V2: 'REWARDSV2_ENABLED',
  RGPAY_ENABLED: 'RGPAY_ENABLED',
  NEIGHBOR_NURTURE_ENABLED: 'NEIGHBOR_NURTURE_ENABLED',
};

export const features = [
  {
    key: featureKeys.REWARDS_V2,
    name: 'New Rewards Page',
    active: process.env.REACT_APP_REWARDSV2_ENABLED === 'true',
  },
  {
    key: featureKeys.RGPAY_ENABLED,
    name: 'RGPay Flow',
    active: process.env.REACT_APP_RGPAY_ENABLED === 'true',
  },
  {
    key: featureKeys.NEIGHBOR_NURTURE_ENABLED,
    name: 'Neighbor Nurture Page',
    active: process.env.REACT_APP_NEIGHBOR_NURTURE_ENABLED === 'true',
  },
];
